.IDetailExtract {
  /* font-family: Helvetica !important; */
  font-family: Helvetica Neue, Helvetica, Arial, sans-serif !important;

  /* background-color: rgba(224, 224, 224, 1); */
}

.coreclaimpdfviewer {
  width: 100%;
}

.coreclaimpdfviewer .react-pdf__Page__canvas {
  width: 100% !important;
  height: 100% !important;
}

.coreclaimpdfviewer .react-pdf__Page__textContent.textLayer {
  width: 100% !important;
  height: 100% !important;
}

.coreclaimpdfviewer .react-pdf__Page__annotations.annotationLayer {
  width: 100% !important;
  height: 100% !important;
}

.coreclaimhidescroll .ant-input-number-handler-wrap {
  display: none;
}

.coreclaimhidescroll .ant-input-number-input {
  padding: 0px !important;
}

.centeritemforsingleunit .ant-input-number-input {
  padding-left: 9px !important;
}

.centeritemfordoubleunit .ant-input-number-input {
  padding-left: 5px !important;
}

.centeritemfortripleunit .ant-input-number-input {
  padding-left: 4px !important;
}

.coreclaimdoceditor .responsive-div {
  height: auto; /* Adjust height based on content */
  max-height: 100%; /* Set maximum height to 100% of parent container */
}

/* Media query for adjusting styles for smaller screens */
@media (max-width: 768px) {
  .responsive-div {
    /* Additional styles for smaller screens */
  }
}
