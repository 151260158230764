.compare-storyboard-modal .ant-modal-content {
  max-height: 80vh;
  display: flex;
  flex-direction: column;
}

.compare-storyboard-modal .ant-modal-body {
  overflow: scroll;
  flex: 1;
}

.compare-storyboard-modal .modal-header {
  display: flex;
  justify-content: space-between;
}

.compare-storyboard-modal .modal-controls {
  display: flex;
  gap: 5px;
  position: relative;
  margin-right: 10px;
  /* padding: 5px; */
  /* background-color: lightgray; */
  border-radius: 5px;
}

.compare-storyboard-modal .modal-controls .close-button {
  position: absolute;
  right: -24px;
  top: -8px;
  font-size: 16px;
  cursor: pointer;
}

.compare-storyboard-modal .modal-controls button {
  font-size: 12px;
  /* padding:0; */
  height: unset;
}
