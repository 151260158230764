.button-container {
  padding: 2px;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.button-container:hover {
  background-color: hsla(0, 0%, 15%, 0.8);
}
