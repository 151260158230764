.card-with-overlay-checkbox.card-container {
  cursor: pointer;
  max-height: 150px;
  min-height: 150px;
  transition: opacity 0.3s ease;
}

.card-with-overlay-checkbox.card-container .overlay-checkbox {
  position: absolute;
  z-index: 1;
}

.card-with-overlay-checkbox.card-container.top-left .overlay-checkbox {
  top: 8px;
  left: 8px;
}

.card-with-overlay-checkbox.card-container.top-right .overlay-checkbox {
  top: 8px;
  right: 8px;
}

.card-with-overlay-checkbox.card-container.bottom-left .overlay-checkbox {
  bottom: 8px;
  left: 8px;
}

.card-container.bottom-right .overlay-checkbox {
  bottom: 8px;
  right: 8px;
}
