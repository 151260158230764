.content-newton .admin-message-container {
  /* border: 1px solid #eaeaea; */
  padding: 16px 0;
  border-radius: 8px;
  display: flex;
  width: 90%;
}

/* .admin-message-container .avatar-container {
} */
.admin-message-container .avatar {
  height: 30px;
  width: 30px;
}

.admin-message-container .message-container {
  flex: 1;
  padding-left: 16px;
  display: flex;
  flex-direction: column;
  gap: 5px;
  width: 100%;
}

.admin-message-container .timestamp {
  font-size: 10px;
  color: #888;
}

.admin-message-container .message-content {
  flex: 1;
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.admin-message-container .message {
  white-space: pre-wrap;
}

.admin-message-container .content {
  flex: 1;
}

.admin-message-container .footer {
  display: flex;
  gap: 10px;
}
.admin-message-container .footer .ant-btn-primary {
  background-color: transparent;
  border: 2px solid #5d2cc9;
  color: #5d2cc9;
  border-radius: 4px;
  padding: 8px 16px;
  font-size: 14px;
  transition: all 0.3s ease;
}
.admin-message-container .footer .ant-btn-primary:not(:disabled):not(.ant-btn-disabled):hover {
  color: #fff;
  background: #5d2cc9;
}
