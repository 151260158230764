.checkbox-tile.content-container {
  position: relative;
  width: 100%;
  height: 100%;
  cursor: pointer;
  user-select: none;

  border: 1px solid lightgray;
  border-radius: 10px;
}

.checkbox-tile .checkbox-overlay {
  position: absolute;
  top: 5px;
  left: 5px;
  z-index: 1;
}
