/* styles.css */
  
  .chatwithdocument-drawer-content {
    display: flex;
    gap: 20px;
  }
  
  .chatwithdocument-left-pane,
  .chatwithdocument-right-pane {
    height: 650px;
    background: white;
    border-radius: 20px;
  }
  
  .chatwithdocument-left-pane {
    width: 30%;
    overflow: auto;
  }
  .chatwithdocument-right-pane {
    max-width: 70%;
    overflow: auto;
  }
  
  .chatwithdocument-header {
    display: flex;
    justify-content: space-between;
    padding: 32px;
    border-bottom: 1px solid #BABABA;
  }
  
  .chatwithdocument-header-title {
    font-size: 16px;
  }
  
  .chatwithdocument-header-clear {
    color: #034EA2;
  }
  
  .chatwithdocument-chat-item {
    display: flex;
    justify-content: space-between;
    padding: 10px;
    cursor: pointer;
  }
  
  .chatwithdocument-chat-item-details {
    display: flex;
    gap: 10px;
  }
  
  .chatwithdocument-chat-item-icon {
    font-size: 16px;
    height: 30px;
    padding-top: 5px;
  }
  
  .chatwithdocument-chat-item-title {
    font-weight: bold;
  }
  
  .chatwithdocument-chat-item-pages {
    display: flex;
  }
  
  .chatwithdocument-chat-item-pages-icon {
    padding-top: 5px;
    height: 18px;
  }
  
  .chatwithdocument-chat-item-pages-text {
    color: #66676B;
    padding-top: 2px;
    padding-left: 5px;
  }
  
  .chatwithdocument-chat-item-time {
    padding: 12px;
    font-size: 12px;
  }
  
  .chatwithdocument-right-header {
    display: flex;
    justify-content: space-between;
    padding: 10px;
    border-bottom: 1px solid #BABABA;
  }
  
  .chatwithdocument-right-header-content {
    display: flex;
    gap: 30px;
  }
  
  .chatwithdocument-right-header-title {
    width: 430px;
    font-size: 16px;
    color: #66676B;
    font-weight: 500;
    display: flex;
  }
  
  .chatwithdocument-right-header-title-bold {
    margin-left: 5px;
    font-weight: bold;
  }
  
  .chatwithdocument-right-header-download {
    color: #034EA2;
    font-size: 16px;
    width: 180px;
    font-weight: 600;
    padding: 2px;
    cursor: pointer;
    position: relative;
  }
  
  .chatwithdocument-right-header-download-arrow {
    padding-left: 7px;
    height: 8px;
    transform: rotate(180deg);
    padding-right: 5px;
    margin-top: 7px;
  }
  
  .chatwithdocument-right-header-download-spin {
    padding-left: 5px;
  }
  
  .chatwithdocument-right-header-download-dropdown {
    position: absolute;
    top: 32px;
    left: 0;
    width: 100%;
    background-color: #fff;
    z-index: 1000;
    border-radius: 3px;
    box-shadow: 0px 2px 3px #0000001F;
  }
  
  .chatwithdocument-right-header-download-dropdown-item {
    padding: 4px;
    cursor: pointer;
    padding-left: 5px;
    font-size: 14px;
    padding-top: 5px;
  }
  
  .chatwithdocument-right-header-reset {
    color: #034EA2;
    font-size: 28px;
    font-weight: 600;
    cursor: pointer;
  }
  
  .chatwithdocument-right-body {
    padding: 15px;
  }
  
  .chatwithdocument-scrollable {
    height: 440px;
    overflow-y: auto;
    overflow-x: unset;
    padding: 10px;
  }
  
  .chatwithdocument-suggestion {
    display: flex;
    padding-left: 10px;
    margin-top: 35px;
  }
  
  .chatwithdocument-suggestion-icon {
    font-size: 15px;
    height: 35px;
    padding-top: 5px;
  }
  
  .chatwithdocument-suggestion-text {
    font-size: 15px;
    margin-left: 25px;
    background: #8C32FF;
    color: white;
    border-radius: 8px;
    padding: 10px;
  }
  
  .chatwithdocument-topics {
    display: flex;
    padding-left: 10px;
    margin-top: 20px;
  }
  
  .chatwithdocument-topics-text {
    font-size: 15px;
    margin-left: 25px;
    background: #F8F9FD;
    color: black;
    border-radius: 8px;
    padding: 10px;
  }
  
  .chatwithdocument-topic-link {
    font-size: 15px;
    margin-left: 25px;
    background: #F8F9FD;
    color: #034EA2;
    text-decoration: underline;
    border-radius: 8px;
    padding: 10px;
    cursor: pointer;
  }
  
  .chatwithdocument-message {
    display: flex;
    padding-left: 10px;
    margin-top: 35px;
  }
  
  .chatwithdocument-message-icon {
    font-size: 15px;
    height: 35px;
    padding-top: 5px;
  }
  
  .chatwithdocument-message-text {
    font-size: 15px;
    margin-left: 25px;
    background: #8C32FF;
    color: white;
    border-radius: 8px;
    padding: 10px;
  }
  
  .chatwithdocument-message-response {
    display: flex;
    padding-left: 10px;
    margin-top: 35px;
  }
  
  .chatwithdocument-response-icon {
    font-size: 15px;
    height: 35px;
    padding-top: 5px;
  }
  
  .chatwithdocument-response-text {
    font-size: 15px;
    margin-left: 25px;
    background: #F8F9FD;
    color: black;
    border-radius: 8px;
    padding: 15px;
  }
  
  .chatwithdocument-input {
    margin-top: 20px;
    border: 1px solid #949494;
    border-radius: 3px;
  }
  