@import url("https://fonts.googleapis.com/css2?family=Nunito+Sans:ital,opsz,wght@0,6..12,200;0,6..12,300;0,6..12,400;0,6..12,500;0,6..12,600;0,6..12,700;0,6..12,800;0,6..12,900;0,6..12,1000;1,6..12,200;1,6..12,300;1,6..12,400;1,6..12,500;1,6..12,600;1,6..12,700;1,6..12,800;1,6..12,900;1,6..12,1000&family=Open+Sans:wght@400;500;600;700&family=Playfair+Display:wght@600;700;900&display=swap");
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

.container1 {
  display: flex;
  justify-content: space-between;
  align-items: stretch;
  min-width: 100vw;
  min-height: 100vh;
  overflow: hidden;
}
/* .column {
    
    border: 1px solid #ddd;
  
  } */
.column {
  padding: 10px;
  border: 1px solid #ddd;
  box-sizing: border-box;
}

.section1 {
  width: 60%;
  display: flex;
  justify-content: center;
  align-items: center;
  background: #003b92 0% 0% no-repeat padding-box;
  opacity: 1;
  position: relative;
}
.animcontainer {
  width: 78.77%;
  height: 80%;
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  /* UI Properties */
  background: rgba(255, 255, 255, 0.2);
  box-shadow: 0 25px 45px rgba(0, 0, 0, 0.2);
  border: 3px solid #5985cb;
  border-radius: 76px;
  backdrop-filter: blur(8.5px);
  -webkit-backdrop-filter: blur(8.5px);
  position: relative;
  z-index: 1;
  background: linear-gradient(45deg, transparent 0%, rgba(255, 255, 255, 0.2) 40%, transparent 100%);
  overflow: hidden;
  &:before {
    content: "";
    position: absolute;
    top: 0%;
    left: -50%;
    width: 200%;
    height: 100%;
    background: linear-gradient(45deg, transparent 0%, rgba(255, 255, 255, 0.05) 40%, transparent 100%);
    animation: shine 2s infinite;
  }
}
.animation {
  width: 65%;
}
.Text {
  height: fit-content;
  overflow: hidden;
  margin-top: 20px;
  margin-bottom: 70px;
}
.animation-text-wrapper {
  text-align: center;
  color: #ffffff;
  margin-top: -3rem;
}

.authentication p {
  text-align: center;
  margin: 0;
}

.authentication p:nth-child(1) {
  text-align: center;
  font: normal normal 600 28px Nunito Sans;
  letter-spacing: 0px;
  color: #ffffff;
  opacity: 1;
}

.authentication p:nth-child(2) {
  text-align: center;
  /* font: normal normal 300 16px/22px Nunito Sans; */
  letter-spacing: 0px;
  color: #ffffff;
  opacity: 1;
}

.authentication p:nth-child(3) {
  text-align: center;
  font: normal normal 300 16px/26px Nunito Sans;
  letter-spacing: 0px;
  color: #ffffff;
  opacity: 1;
  letter-spacing: 0px;
  color: #ffffff;
  opacity: 1;
}

.login-container {
  /* width: 40%; */
  width: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  background: #ffffff 0% 0% no-repeat padding-box;
  opacity: 1;
  backdrop-filter: blur(50px);
  -webkit-backdrop-filter: blur(50px);
  flex-direction: column;
  gap: 5px;
}
.form-container {
  display: flex;
  align-items: flex-start;
  width: 400px;
  height: 100px;
  padding: 20px;
  border: 1px solid #ccc;
  border-radius: 5px;
}
.second-div img {
  max-width: 100%;
  height: auto;
}

.second-div {
  text-align: left;
}
.second-content {
  display: flex;
  align-items: start;
  justify-content: start;
  flex-direction: column;
}

.login-space {
  margin-top: 10px;
}
