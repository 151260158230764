.applayout .ant-layout-sider,
.applayout .ant-layout-sider-trigger {
  background-color: rgb(3, 78, 162);
}

.backgroundWhite {
  background: #fff;
}
.d-flex {
  display: flex;
}

.justify-content-center {
  justify-content: center;
}

.align-items-center {
  align-items: center;
}

.CTFooter {
  text-align: center;
  position: sticky;
  bottom: 0px;
  height: 100px;
  justify-content: flex-start;
  align-items: center;
  padding: 30px;
  border-bottom: 0px;
  border-radius: 0px;
  background: #e6f0ff;
}

.overflowY {
  overflow-y: scroll;
}

.font-nunito-sans {
  /* font-style: normal normal bold 24px/32px Nunito Sans; */
  font-size: 18px;
  font-weight: bold;
}

.iframe-container {
  position: relative;
  padding-bottom: 56.25%; /* 16:9 aspect ratio, adjust as needed */
  height: 0;
  overflow: hidden;
}

.iframe-container iframe {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.optimize-font {
  text-align: left;
  font: normal normal bold 24px/32px Nunito Sans;
  letter-spacing: 0px;
  opacity: 1;
  font-size: 18px;
}

.optimize-font-blue {
  color: #0546a5;
}

.flex-direction-column {
  flex-direction: column;
}

.justify-content-end {
  justify-content: flex-end;
}
.align-items-start {
  align-items: flex-start;
}
.pointer {
  cursor: pointer;
}
.semi-bold-font {
  text-align: left;
  font: normal normal 600 16px/18px Nunito Sans;
  letter-spacing: 0px;
  color: #231f20;
  text-transform: capitalize;
  opacity: 1;
}
.button-font {
  text-align: right;
  font: normal normal bold 12px/22px Nunito Sans;
  letter-spacing: 0px;
  color: #66676b;
  text-transform: uppercase;
  opacity: 1;
}
.button-blue {
  background-color: #034ea2;
  color: white !important;
  border: none !important;
}
.button-with-border {
  color: #0546a5;
  border-color: #0546a5;
}
/*Content Transcreation Rules Tab*/
.content-transcreation .upload-rules-tab.ant-tabs .ant-tabs-nav {
  margin: 0;
}
.content-transcreation .upload-rules-tab.ant-tabs .ant-tabs-nav-list {
  font-weight: 700;
}

.predis-dialog {
  padding: 0px !important;
  margin: -9px;
}

.predis-dialog svg {
  display: none;
  color: black !important;
  padding: 2px 3px !important;
  background: #e1e1e1;
  border-radius: 14px;
  margin: 10px;
  top: 7px !important;
}
