.app-sidebar .ant-menu .ant-menu-item-selected {
  position: relative;
  background-color: #f5f0ff;
  border-radius: 0;
}
.app-sidebar .ant-menu .ant-menu-item-selected::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 5px;
  background-color: #5d2cc9;
  border-radius: 0 12px 12px 0;
  z-index: 1;
}

.app-sidebar .ant-menu .anticon {
  color: #5d2cc9 !important;
}

.app-sidebar.ant-layout-sider-collapsed {
  max-width: 65px !important;
  min-width: 65px !important;
}
