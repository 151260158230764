.narration-title-bar {
  margin-bottom: 1rem;
}
.narration-area {
  position: relative;
  padding: 20px 40px;
  /* border: 1px solid hsla(0, 0%, 74.1%, 0.3); */
  border-radius: 8px;
  transition: all 0.3s, height 0s;
  background-color: white;
}
.narration-textarea {
  width: 100%;
  min-height: 150px;
  resize: vertical;
  overflow: auto;
  border: none;
  font-size: 14px;
  outline: none;
  line-height: 24px;
}

.content-translation .tab-text {
  font-size: 10px;
  line-height: 12px;
}

.content-translation .main-menu-item {
  display: flex;
  flex-direction: column;
}

.content-translation .sidebar-wrapper {
  position: absolute;
  inset: 0;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  height: 100%;
}
.content-translation .sidebar-wrapper.open {
  width: 100%;
}
.content-translation .sidebar-wrapper .btn-collapse-sidebar {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 48px;
  width: 24px;
  background-color: #e9ecec;
  border: none;
  border-radius: 5px 0 0 5px;
  box-shadow: none;
}
.content-translation .sidebar {
  background-color: #fff;
  height: 100%;
  flex: 1;
}
.content-translation .sidebar.ant-tabs > .ant-tabs-nav {
  width: 64px;
}
.content-translation .sidebar.ant-tabs .ant-tabs-content {
  height: 100%;
}
.content-translation .sidebar.ant-tabs > .ant-tabs-nav .ant-tabs-tab .main-menu-item .icon {
  font-size: 20px;
  margin: 0 auto 5px;
}
.content-translation .sidebar.ant-tabs > .ant-tabs-nav .ant-tabs-tab {
  color: #31353c;
  padding: 14px 0;
}
.content-translation .sidebar.ant-tabs > .ant-tabs-nav .ant-tabs-tab .ant-tabs-tab-btn {
  display: block;
  margin: 0 auto;
}
.content-translation .sidebar.ant-tabs > .ant-tabs-nav .ant-tabs-tab + .ant-tabs-tab {
  margin-top: 0;
}
.content-translation .sidebar-wrapper.open .sidebar.ant-tabs > .ant-tabs-nav .ant-tabs-tab-active {
  background-color: #4868ff;
}
.content-translation .sidebar-wrapper.open .sidebar.ant-tabs > .ant-tabs-nav .ant-tabs-tab-active .ant-tabs-tab-btn {
  color: #fff;
}
.content-translation .sidebar.ant-tabs > .ant-tabs-nav .ant-tabs-tab-active .ant-tabs-tab-btn {
  color: rgba(0, 0, 0, 0.85);
}

/*Hide SideBar content*/
.content-translation .sidebar-wrapper .close .ant-tabs-content-holder {
  display: none;
}

/*SideBar Tab Content*/
.content-translation .sidebar .tab-content {
  padding: 5px;
}
.content-translation .sidebar.ant-tabs > .ant-tabs-content-holder > .ant-tabs-content > .ant-tabs-tabpane {
  height: 100%;
  padding: 0;
}

/*Layering*/
.content-translation .sidebar .layer-control .btn-layering {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 50px;
  height: 35px;
}
.content-translation .sidebar .layer-control .ant-btn-sm .icon {
  font-size: 12px;
}
.content-translation .sidebar .layer-control .icon:not(.own-colors) svg {
  width: 1em;
  height: 1em;
}

/*Context Menu*/
.content-translation .canvas-context-menu .ant-dropdown-menu-submenu-title {
  align-items: center;
  display: flex;
}
.content-translation .canvas-context-menu {
  /* width: 200px; */
  -webkit-animation-duration: 0s !important;
  animation-duration: 0s !important;
}
/* .canvas-context-menu .ant-dropdown-menu-submenu .ant-dropdown-menu-submenu-title {
  -webkit-padding-end: 12px;
  padding-inline-end: 12px;
}
.canvas-context-menu .ant-dropdown-menu-submenu .ant-dropdown-menu-submenu-title .icon:last-child {
  margin: 0;
}
.canvas-context-submenu {
  margin: 0 10px;
}
.canvas-context-menu,
.canvas-context-submenu,
.canvas-layer-submenu,
.speech-menu {
  -webkit-user-select: none;
  -ms-user-select: none;
  user-select: none;
}
.canvas-context-menu .ant-dropdown-menu-item .icon,
.canvas-context-submenu .ant-dropdown-menu-item .icon,
.canvas-layer-submenu .ant-dropdown-menu-item .icon,
.speech-menu .ant-dropdown-menu-item .icon {
  font-size: 18px;
}
.canvas-context-menu .ant-dropdown-menu-item .menu-item-label,
.canvas-context-submenu .ant-dropdown-menu-item .menu-item-label,
.canvas-layer-submenu .ant-dropdown-menu-item .menu-item-label {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.canvas-context-menu .ant-dropdown-menu-item .menu-item-label span,
.canvas-context-submenu .ant-dropdown-menu-item .menu-item-label span,
.canvas-layer-submenu .ant-dropdown-menu-item .menu-item-label span {
  display: block;
}
.canvas-context-menu .ant-dropdown-menu-item .menu-item-label .ant-typography-secondary,
.canvas-context-submenu .ant-dropdown-menu-item .menu-item-label .ant-typography-secondary,
.canvas-layer-submenu .ant-dropdown-menu-item .menu-item-label .ant-typography-secondary {
  font-size: 12px;
  line-height: 12px;
}
 */

.avatar-apply-all-popconfirm.dark-popconfirm .ant-popover-inner {
  /* background: rgba(49, 53, 59, .9490196078431372); */
  border-radius: 8px;
}
.avatar-apply-all-popconfirm .ant-popconfirm-inner-content {
  display: flex;
  justify-content: space-between;
  min-width: 290px;
}
.avatar-apply-all-popconfirm .ant-popconfirm-message {
  margin-bottom: 0;
  align-items: center !important;
}
.avatar-apply-all-popconfirm .ant-popconfirm-message .current-label {
  color: #bdbdbd;
}
.avatar-apply-all-popconfirm .ant-popconfirm-message .type-label {
  /* color: #fff; */
  font-weight: 700;
}