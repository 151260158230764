.content-newton .chat-bot-box {
  width: fit-content;
  margin: auto;
}

/*Copied from Content-AI ChatBot*/
.content-newton .chat-bot-box {
  /* margin-left: 42%; */
  text-align: right;
  position: relative;
}
.content-newton .chat-bot-input {
  position: absolute;
  bottom: 0;
  /* left: -21%; */
  right: 190px;
  user-select: none;
  display: flex;
  align-items: center;
  height: 2.5rem;
  width: 12rem;
  border: none;
  background: #fff;
  border-radius: 8px;
  color: #034ea2;
  font-size: 15px;
  padding: 0 24px;
  font-weight: 600;
}

.content-newton .chat-bot-input-cursor {
  animation: chat-bot-input-cursor-blink 0.8s linear infinite;
}
@keyframes chat-bot-input-cursor-blink {
  0% {
    height: 24px;
  }
  50% {
    height: 16px;
  }
  100% {
    height: 24px;
  }
}

.content-newton .chat-bot-image {
  height: 200px;
  /*   width: 444px;
    height: 369px; */
  flex-shrink: 0;
  animation: chat-bot-translate 4s linear infinite;
}
@keyframes chat-bot-translate {
  10% {
    transform: translate(0px, 0px);
  }
  40% {
    transform: translate(-20px, -20px);
  }
  60% {
    transform: translate(-20px, -20px);
  }
  90% {
    transform: translate(0px, 0px);
  }
}
