.chatbot-root {
  position: absolute;
  bottom: 1rem;
  right: 1rem;
  display: flex;
  flex-direction: column;
  gap: 10px;
}
.chatbot-root.open {
  top: 1rem;
  width: 500px;
}

.chatbot-root .chatbot-button {
  border-radius: 50%;
  height: 50px;
  width: 50px;
  align-self: end;
}

.chatbot-container {
  background-color: white;
  width: 100%;
  flex: 1 1 auto;
  border-radius: 10px;
  box-shadow: 0 2px 12px rgba(0, 0, 0, 0.4);
  overflow: hidden;
  display: flex;
  flex-direction: column;
}
.hide {
  display: none;
}

.chatbot-container .header {
  background-color: #f8f9fa;
  height: 35px;
  width: 100%;
  display: flex;
  padding: 0 10px;
  font-weight: 700;
  box-shadow: 0 1px 0 rgba(0, 0, 0, 0.2);
  z-index: 1; /*To prevent shadow hiding due to overlapping content while scrolling*/
}
.chatbot-container .h-title {
  flex: 1;
  display: flex;
  justify-content: center;
  align-items: center;
}
.chatbot-container .minimize-btn {
  border: none;
  padding: 0;
  height: inherit;
  width: initial;
  background: inherit;
}

.chatbot-container .body {
  flex: 1;
  padding: 10px;
  display: flex;
  gap: 5px;
}
.chatbot-container .body {
  display: flex;
  flex-direction: column;
  gap: 10px;
  overflow-y: scroll;
}

.chatbot-container .body .message-area {
  display: flex;
  flex-direction: column;
  gap: 5px;
  max-width: 80%;
}
.chatbot-container .body .action-buttons {
  display: flex;
  gap: 5px;
}

.chatbot-container .body .admin-message {
  display: flex;
  align-items: flex-start;
}
.chatbot-container .body .user-message {
  display: flex;
  flex-flow: row-reverse;
  align-items: flex-end;
}
.chatbot-container .body .message-error {
  display: flex;
  height: 36px;
  color: red;
  margin: 0 10px;
}
.chatbot-container .body .avatar {
  width: 36px;
  height: 36px;
  /* background-color: #1677ff; */
  background-color: white;
  border-radius: 50%;
  font-size: 25px !important;
  /* color: #f0ecec; */
  color: black;
}
.chatbot-container .body .admin-message .avatar {
  /* background: #0546a5; */
  padding: 6px;
}
.chatbot-container .message {
  position: relative;
  background-color: #f1f1f1;
  border-radius: 10px;
  padding: 5px 10px;
  /* max-width: 80%; */
  margin-left: 10px;
  min-height: 36px;
  display: flex;
  align-items: center;
}
.chatbot-container .user-message .message {
  margin-right: 10px;
  margin-left: 0px;
  background-color: #c3e3f0;
}
.chatbot-container .message-content {
  white-space: pre-wrap;
}

.chatbot-container .admin-message .message::before {
  content: "";
  position: absolute;
  top: 10px;
  left: -8px;
  width: 0;
  height: 0;
  border-style: solid;
  border-width: 8px 8px 8px 0;
  border-color: transparent #f1f1f1 transparent transparent;
}
.chatbot-container .user-message .message::after {
  content: "";
  position: absolute;
  bottom: 10px;
  right: -8px;
  width: 0;
  height: 0;
  border-style: solid;
  border-width: 8px 0px 8px 8px;
  border-color: transparent transparent transparent #c3e3f0;
}

.chatbot-container .footer {
  background-color: #f8f9fa;
  display: flex;
  gap: 5px;
  padding: 10px;
  margin-bottom: 20px;
  box-shadow: 0 -1px 0 rgba(0, 0, 0, 0.2), 0 1px 0 rgba(0, 0, 0, 0.2);
  z-index: 1; /*To prevent shadow hiding due to overlapping content while scrolling*/
}
.chatbot-container .footer input {
  height: 40px;
}
.chatbot-container .footer button {
  height: 40px;
}
