.semantic-search .container {
    max-width: 1366px;
  
  }
  .semantic-search header {
    background-color: #fff;
    padding: 20px 0 10px;
  }
  
  .semantic-search .menu-arrow {
    position: fixed;
    top: 345px;
    z-index: 100;
    width: 16px;
    padding-left: 7px;
    cursor: pointer;
    margin-left: -10px;
    left: 10px;
    height: 200px;
  }
  .semantic-search .menu-arrow-close {
    left: 10px;
    background-color: #034ea2;
    position: fixed;
    top: 290px;
    z-index: 100;
    width: 16px;
    padding-left: 4px;
    padding-right: 3px;
    margin-left: -10px;
    cursor: pointer;
    border-radius: 0px 10px 10px 0;
    height: 200px;
  }
  
  .semantic-search .fixed-menu {
    width: 250px;
    left: 10px;
    background-color: #034ea2;
    position: fixed;
    top: 290px;
    z-index: 99;
    color: #fff;
    padding: 20px;
    list-style-type: none;
    border-radius: 0px 10px 10px 0;
    margin-left: -10px;

  }
  .semantic-search .fixed-menu li {
    padding: 6px 0 6px 12px;
    font-weight: 500;
    margin-bottom: 8px;
  }
  .semantic-search .fixed-menu li a {
    color: #fff;
    text-decoration: none;
    padding: 6px 0 6px 12px;
    font-weight: 500;
    margin-bottom: -12px; 
    display: block;
  }
  .semantic-search .fixed-menu li a.active,
  .semantic-search .fixed-menu li a:hover {
    background-color: #fff;
    color: #034ea2 !important; /*To prevent override from bootstrap.css a:hover. Would be removed after completely localizing the bootstrap.css*/
    border-radius: 4px;
  }