.ct-stepper .progressDot {
  width: 20px !important;
  height: 20px !important;
  border-radius: 50%;
  padding: 1.5px;
  border: 3px solid #0546a5 !important;
  transform: translate(-25%, -25%);
}

.ct-stepper .ant-steps-item-finish > .ant-steps-item-container > .ant-steps-item-tail::after {
  background-color: #0546a5 !important;
}
