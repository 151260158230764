.ct-upload-area {
  display: block;
  border: 1px dotted black;
  border-radius: 8px;
  width: 100%;
  text-align: center;
}

.ct-upload-area .ant-upload {
  display: block;
  width: 100%;
  height: 100%;
}

.ct-upload-area .upload-area-body {
  padding: 24px;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.ct-upload-area .upload-area-body button {
  align-self: center;
  width: fit-content;
}

.uploaded-files-section {
  display: flex;
  flex-direction: column;
}
