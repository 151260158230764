.module-preview-container {
  background-color: #f0f7ff; /*  #005c8a; */
  color: #5f5f5f;
  border-radius: 8px;
  height: 100%;
  overflow: auto;
}

.module-preview-container .module-title {
  padding: 5px;
  text-align: center;
  font-size: 16px;
  font-weight: 800;
  border-bottom: 1px solid white;
}

.module-preview-container .content-container {
  padding: 20px;
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.module-preview-container .scrollable {
  max-height: 300px;
  overflow-y: auto;
  border-color: #0000001a;
}

.module-preview-container .claim-item,
.module-preview-container .non-claim-item {
  margin-bottom: 10px;
}

.module-preview-container .images-container {
  display: flex;
  gap: 10px;
  overflow-x: scroll;
  width: 100%;
  /* flex-wrap: nowrap; */
  /* align-items: center; */
}

.module-preview-container .images-container img {
  width: 100%;
  border-radius: 10px;
}
