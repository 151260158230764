.batchqa-card-element {
    padding-left: 20px;
}

.batchqa-step-header {
    color: #9A9A9A;
}

.batchqa-step-title {
    font-weight: bold;
}

.batchqa-select-all {
    font-weight: bold;
    font-size: 14px;
    color: #1E4DA1;
    cursor: pointer;
}

.batchqa-question-list {
    margin-top: 5px;
    height: 450px;
    overflow-y: scroll;
}

.batchqa-question-item {
    display: flex;
    align-items: center;
    margin-bottom: 10px;
}

.batchqa-question-text {
    margin-left: 10px;
    font-weight: bold;
    cursor: pointer;
}

.batchqa-tip {
    display: flex;
    justify-content: space-between;
    color: #999999;
    margin-top: 5px;
}

.batchqa-folder-list {
    margin-top: 5px;
    height: 450px;
    overflow-y: scroll;
    border-bottom: 1px solid black;
}

.batchqa-folder-item {
    display: flex;
    align-items: center;
    margin-bottom: 10px;
}

.batchqa-folder-name {
    margin-left: 10px;
    font-weight: bold;
}

.batchqa-upload-button {
    font-weight: bold;
    font-size: 18px;
    color: #1E4DA1;
    cursor: not-allowed;
}

.batchqa-button-link {
    color: #1E4DA1;
}

@media (max-height: 576px) {
    .batchqa-question-list {
        height: 200px; /* Smaller height for very small screens */
    }
    .batchqa-folder-list {
        height: 200px; /* Smaller height for very small screens */

    }
}

/* Medium devices (tablets, 768px and up) */
@media (min-height: 577px) and (max-height: 675px) {
    .batchqa-question-list {
        height: 350px; /* Medium height for tablets */
    }
    .batchqa-folder-list {
        height: 350px; /* Smaller height for very small screens */

    }
    
}

