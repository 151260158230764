.element-item {
  cursor: pointer;
  position: relative;
  overflow: hidden;
  border: 1px solid "@default-border-color";
  border-radius: 4px;
  width: 97px;
  height: 97px;
  &:hover {
    .element-item-actions {
      &-bg {
        background-color: rgba(0, 0, 0, 0.5);
      }
      svg {
        fill: rgba(255, 255, 255, 0.75);
      }
    }
  }
  &-img {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 97px;
    height: 97px;
    object-fit: cover;
  }
  .element-item-actions {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 40px;
    font-size: 16px;
    &-bg {
      display: flex;
      justify-content: center;
      align-items: center;
      position: absolute;
      top: 0;
      width: 97px;
      height: 97px;
      transition: background-color 0.3s;
    }
    svg {
      fill: rgba(255, 255, 255, 0);
      transition: fill 0.3s;
    }
  }
}
