.syncedNotification-container {
    background: white;
    padding: 10px;
    box-shadow: 0px 0px 10px #0000001F;
    border: 1px solid black;
    border-radius: 10px;
    padding-top: 4px;
  }
  
  .syncedNotification-header {
    display: flex;
    justify-content: space-between;
  }
  
  .syncedNotification-title {
    font-weight: bold;
    font-size: 16px;
    margin-left: 5px;
  }
  
  .syncedNotification-read-all {
    font-weight: bold;
    font-size: 14px;
    color: rgb(30, 77, 161);
    margin-right: 5px;
    cursor: pointer;
  }
  
  .syncedNotification-content {
    max-height: 300px;
    overflow-y: auto;
    width: 600px;
    overflow-x: hidden;
    margin-bottom: 15px;
  }
  
  .syncedNotification-item {
    line-height: 20px;
    margin-bottom: 15px;
    background: #EDF4FF;
    padding: 10px;
    border-radius: 5px;
    display: flex;
    flex-direction: column;
    gap: 5px;
  }
  
  .syncedNotification-item-header {
    display: flex;
    justify-content: space-between;
  }
  
  .syncedNotification-item-heading {
    font-weight: bold;
  }
  
  .syncedNotification-item-date {
    font-weight: bold;
    font-size: 12px;
    color: rgb(102, 103, 107);
  }
  
  .syncedNotification-item-content {
    margin-top: 5px;
  }
  