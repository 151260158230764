/* styles.css */
/* styles.css */

.card-element {
    width: 100%;
    opacity: 1;
    border-radius: 25px;
    box-shadow: 0px 0px 2px #0000001F;
    height: 78vh; /* Set initial height as 80% of viewport height */
  }
  
  .card-element2 {
    width: 100%;
    opacity: 1; 
    overflow-y: scroll;
    border-radius: 25px;
    box-shadow: 0px 0px 2px #0000001F;
    height: 78vh; /* Set initial height as 80% of viewport height */
  }
  .Previous-Next-Header {
    padding-right: 18px;
    font-size: 23px;
    font-weight: 500;
    word-wrap: break-word;
    max-width: 500px; 
    color: #66676B;
    padding-left: 30px; 
  }
  .next-insight-page ul{
    padding-left: 25px;
  }
  .next-insight-page ol{
    padding-left: 25px;
     }


  @media (min-width: 1321px) and (max-width: 1480px) {
    .Previous-Next-Header {
      max-width: 400px;
    }
  }
  
  @media (max-width: 1320px) {
    .Previous-Next-Header {
      font-size: 20px;
      max-width: 320px;
    }
  }
  
  /* Media query for adjusting height based on inner height */
  
  