.chatmessanger {
    border-radius: 50px;
    height: 55px;
    width: 55px;
    background: #1E4DA1;
    position: absolute;
    z-index: 9999999999999999999;
    right: 300px;
    bottom: 50px;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
  }
  .Card {
    width: 100%;
    opacity: 1;
    border-radius: 10px;
    box-shadow: 0px 0px 2px #0000001F;
    height: 550px;
  }
  .card-col {
    background: #EDF4FF;
    border-radius: 12px;
    cursor: pointer;
    margin-top: 10px;
    display: flex;
    justify-content: center;
    padding: 0;

  }
  
  .card-col.selected {
    border: 5px solid #1E4DA1;
  } 
  
  .image-container {
    width: 110px; /* Ensure the image fills the width of the card-col */ 
    height: 100px; /* Ensure the image fills the height of the card-col */
    border-radius: 8px; /* Optional: Add border radius to the image */
  }
  
  .image-description{
    text-align: left;
    font: normal normal normal 14px/26px Nunito Sans;
    letter-spacing: 0px;
    color: #66676B;
    opacity: 1;
    line-height: 26px;
    margin-top: 3px;
    overflow-y: auto;
    width: 620px;
    height: 380px;
    padding-left: 50px;
  }
  .semantic-video-description{
    text-align: left;
    font: normal normal normal 14px/26px Nunito Sans;
    letter-spacing: 0px;
    color: #66676B;
    opacity: 1;
    line-height: 26px;
    margin-top: 30px;
    overflow-y: auto;
    width: 620px;
    height: 380px;
    padding-left: 50px;
  }
  /* Media query for screens between 1200px and 1299px */
@media screen and (min-width: 1200px) and (max-width: 1299px) {
  .chatmessanger {
      right: 310px; /* Adjust the positioning for screens between 1200px and 1299px */
      bottom: 50px; /* Adjust the positioning for screens between 1200px and 1299px */
  }
  .image-description{
    width:420px;
  }
  .semantic-video-description{
    width:420px;
  }
}

/* Media query for screens between 1300px and 1399px */
@media screen and (min-width: 1300px) and (max-width: 1399px) {
  .chatmessanger {
      right: 260px; /* Adjust the positioning for screens between 1300px and 1399px */
      bottom: 50px; /* Adjust the positioning for screens between 1300px and 1399px */
      
  }
  .image-description{
    width:520px;
  }
  .semantic-video-description{
    width:520px;
  }
}
/* Media query for screens between 1300px and 1399px */
@media screen and (min-width: 1200px) and (max-width: 1299px) {
  .chatmessanger {
      right: 240px; /* Adjust the positioning for screens between 1300px and 1399px */
      bottom: 50px; /* Adjust the positioning for screens between 1300px and 1399px */
  }
}
@media screen and (min-width: 1399px) and (max-width: 1499px) {
  .chatmessanger {
      right: 280px; /* Adjust the positioning for screens between 1300px and 1399px */
      bottom: 50px; /* Adjust the positioning for screens between 1300px and 1399px */
  }
  .image-description{
    width:600px;
  }
  .semantic-video-description{
    width:600px;
  }
}
/* Media query for screens with a height between 500px and 599px */
@media screen and (min-height: 500px) and (max-height: 599px) {
  .chatmessanger {
      bottom: 0px; /* Adjust the positioning for screens between 500px and 599px */
  }
}

/* Media query for screens with a height between 600px and 649px */
@media screen and (min-height: 600px) and (max-height: 649px) {
  .chatmessanger {
      bottom: 4px; /* Adjust the positioning for screens between 600px and 649px */ 
  }
}

/* Media query for screens with a height between 650px and 699px */
@media screen and (min-height: 650px) and (max-height: 699px) {
  .chatmessanger {
      bottom: 27px; /* Adjust the positioning for screens between 650px and 699px */
  }
}

/* Media query for screens with a height between 700px and 749px */
@media screen and (min-height: 700px) and (max-height: 749px) {
  .chatmessanger {
      bottom: 50px; /* Adjust the positioning for screens between 700px and 749px */
  }
}

/* Media query for screens with a height between 750px and 799px */
@media screen and (min-height: 750px) and (max-height: 799px) {
  .chatmessanger {
      bottom: 75px; /* Adjust the positioning for screens between 750px and 799px */
  }
}

/* Media query for screens with a height between 800px and 849px */
@media screen and (min-height: 800px) and (max-height: 849px) {
  .chatmessanger {
      bottom: 100px; /* Adjust the positioning for screens between 800px and 849px */
  }
}

/* Media query for screens with a height between 850px and 899px */
@media screen and (min-height: 850px) and (max-height: 899px) {
  .chatmessanger {
      bottom: 125px; /* Adjust the positioning for screens between 850px and 899px */
  }
}

/* Media query for screens with a height between 900px and 949px */
@media screen and (min-height: 900px) and (max-height: 949px) {
  .chatmessanger {
      bottom: 130px; /* Adjust the positioning for screens between 900px and 949px */
  }
}
