.cn-fullscreen-modal.ant-modal {
  width: calc(100vw - 40px) !important;
  max-width: none !important;
  top: 20px !important;
  padding-bottom: 0;
}
.cn-fullscreen-variable-width-modal.ant-modal {
  /* width: calc(100vw - 40px) !important; */
  max-width: none !important;
  top: 20px !important;
  padding-bottom: 0;
}
.cn-fullscreen-variable-width-modal .ant-modal-content,
.cn-fullscreen-modal .ant-modal-content {
  height: calc(100vh - 40px);
  display: flex;
  flex-direction: column;
}
.cn-fullscreen-variable-width-modal .ant-modal-body,
.cn-fullscreen-modal .ant-modal-body {
  height: 100%;
  overflow-y: auto;
}

.content-newton .ant-btn-primary {
  background-color: transparent;
  border: 2px solid #5d2cc9;
  color: #5d2cc9;
  border-radius: 4px;
  padding: 8px 16px;
  font-size: 14px;
  transition: all 0.3s ease;
}
.content-newton .ant-btn-primary:not(:disabled):not(.ant-btn-disabled):hover {
  color: #fff;
  background: #5d2cc9;
}
