@import url("https://fonts.googleapis.com/css2?family=Nunito+Sans:ital,opsz,wght@0,6..12,200;0,6..12,300;0,6..12,400;0,6..12,500;0,6..12,600;0,6..12,700;0,6..12,800;0,6..12,900;0,6..12,1000;1,6..12,200;1,6..12,300;1,6..12,400;1,6..12,500;1,6..12,600;1,6..12,700;1,6..12,800;1,6..12,900;1,6..12,1000&family=Open+Sans:wght@400;500;600;700&family=Playfair+Display:wght@600;700;900&display=swap");

body * {
  font-family: Nunito Sans !important;
}

.App {
  text-align: center;
}

.ant-select-selection-placeholder {
  color: #6c6a6a !important;
}

::-webkit-scrollbar {
  width: 4px;
  height: 4px;
}
::-webkit-scrollbar-track {
  border-radius: 12px;
}
::-webkit-scrollbar-thumb {
  background: lightgray;
  border-radius: 12px;
}
