.sidebar {
  background: red;
}

.sidebar .ant-drawer-right .ant-drawer-content-wrapper {
  box-shadow: none;
}

.sidebar .ant-drawer .ant-drawer-content-wrapper {
  width: 100% !important;
}
