/* Base styles */
.multillm-container {
    background: white;
    width: 80%;
    border-radius: 12px;
    box-shadow: 0px 0px 5px #0000001F;
    position: fixed;
    bottom: 30px;
    left: 180px;
    z-index: 2;
  }
  
  /* Media queries for different screen widths */
  @media (min-width: 1200px) and (max-width: 1300px) {
    .multillm-container {
     left:120px;
    }
  }
  @media (min-width: 1300px) and (max-width: 1400px) {
    .multillm-container {
     left:160px;
    }
  }
  @media (min-height: 600px) and (max-height: 700px) {
    .multillm-container {
     bottom:10px;
    }
  }
 