.cr-stepper {
  height: 80%;
}

/* Font Size */
.cr-stepper.ant-steps.ant-steps-small .ant-steps-item-title {
  padding-inline-end: 12px;
  font-size: 12px;
}

/* Finished Item */
.cr-stepper .ant-steps-item.ant-steps-item-finish .ant-steps-item-icon {
  background: #f5f5f5;
  border: #5d2cc9 2px solid;
}
.cr-stepper .ant-steps-item.ant-steps-item-finish .ant-steps-item-icon > .ant-steps-icon {
  color: #5d2cc9;
  stroke-width: 80px;
  stroke: #5d2cc9;
}

/* CSS of lines */
.cr-stepper.ant-steps .ant-steps-item .ant-steps-item-tail::after {
  position: absolute;
  top: 0;
}
.cr-stepper.ant-steps
  .ant-steps-item-finish
  > .ant-steps-item-container
  > .ant-steps-item-tail::after {
  background-color: #5d2cc9;
}
.cr-stepper.ant-steps
  .ant-steps-item-wait
  > .ant-steps-item-container
  > .ant-steps-item-tail::after {
  background-color: #66676b;
}
.cr-stepper.ant-steps
  .ant-steps-item-process
  > .ant-steps-item-container
  > .ant-steps-item-tail::after {
  background-color: #66676b;
}

/* Current Progressing Item */
.cr-stepper .ant-steps-item-process .ant-steps-item-icon {
  color: #5d2cc9;
  background-color: #5d2cc9;
  border-color: #5d2cc9;
}
.cr-stepper .ant-steps-item-process .ant-steps-item-icon > .ant-steps-icon {
  color: #5d2cc9;
}
/* .cr-stepper .ant-steps-item-process.ant-steps-item-active .ant-steps-item-icon {
  color: #5d2cc9;
  background-color: #5d2cc9;
  border-color: #5d2cc9;
}
.cr-stepper .ant-steps-item-process.ant-steps-item-active .ant-steps-item-icon > .ant-steps-icon {
  color: #5d2cc9;
} */

/* Waiting Item */
.cr-stepper .ant-steps-item.ant-steps-item-wait .ant-steps-item-icon > .ant-steps-icon {
  color: #66676b;
}
.cr-stepper .ant-steps-item.ant-steps-item-wait .ant-steps-item-icon {
  color: #66676b;
  background-color: #66676b;
  border-color: #66676b;
}

/* Bold text for In-Progress step */
/* .cr-stepper.ant-steps .ant-steps-item-process .ant-steps-item-container .ant-steps-item-title {
  font-weight: 700;
} */
/* Testing Bold Text for Active step */
.cr-stepper.ant-steps .ant-steps-item-active .ant-steps-item-container .ant-steps-item-title {
  font-weight: 700;
}

/* Icon size */
.cr-stepper.ant-steps.ant-steps-small .ant-steps-item-icon {
  width: 16px;
  height: 16px;
  font-size: 10px;
  line-height: 18px;
}
.cr-stepper.ant-steps.ant-steps-small .ant-steps-item-icon > .ant-steps-icon {
  font-size: 8px;
  top: -3px;
  position: relative;
}

/* Reduce the size of the stepper text */
.cr-stepper.ant-steps.ant-steps-small .ant-steps-item-title {
  font-size: 12px;
  line-height: 20px;
}
.cr-stepper.ant-steps.ant-steps-small .ant-steps-item-description {
  font-size: 10px;
}

/* Vertical Line Alignment */
.cr-stepper.ant-steps.ant-steps-vertical.ant-steps-small
  .ant-steps-item-container
  .ant-steps-item-tail {
  top: 4px;
  inset-inline-start: 7.5px;
}

/* Check Icon inner check alignment */
.cr-stepper.ant-steps.ant-steps-small .ant-steps-item-icon {
  position: relative;
  top: 3px;
}
