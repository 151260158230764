@import url("https://fonts.googleapis.com/css2?family=Nunito+Sans:ital,opsz,wght@0,6..12,300;0,6..12,400;0,6..12,500;0,6..12,600;0,6..12,700;1,6..12,300&display=swap");

/*------------------------------*/
/*______Bootstrap classes_______*/
/*------------------------------*/

.content-ai {
  height: 100vh;
  font-weight: 400;
  line-height: 1.5;
  color: #212529;
  text-align: left;
  background: linear-gradient(107deg, #dceff2 0.53%, #d9eced 13.12%, #eaf1ee 23.55%, #f2f3ee 48.33%, #f2f3ee 74.9%, #ddd3f5 100%);
  overflow-y: scroll;
  display: flex;
  flex-direction: column;
}

.content-ai .p-0 {
  padding: 0 !important;
}

.content-ai .container {
  width: 100%;
  margin-right: auto;
  margin-left: auto;
}

.content-ai .py-4 {
  padding-bottom: 1.5rem !important;
  padding-top: 1.5rem !important;
}

.content-ai .row {
  display: flex;
  flex-wrap: wrap;
  margin-right: -15px;
  margin-left: -15px;
}

.content-ai .col {
  flex-basis: 0;
  flex-grow: 1;
  max-width: 100%;
}

.content-ai .col,
.content-ai .col-2,
.content-ai .col-3,
.content-ai .col-4,
.content-ai .col-md-4,
.content-ai .col-5,
.content-ai .col-6,
.content-ai .col-7,
.content-ai .col-8,
.content-ai .col-md-8,
.content-ai .col-9,
.content-ai .col-12,
.content-ai .col-10 {
  position: relative;
  width: 100%;
  min-height: 1px;
  padding-right: 15px;
  padding-left: 15px;
}

.content-ai .col-2 {
  flex: 0 0 16.666667%;
  max-width: 16.666667%;
}

.content-ai .col-3 {
  flex: 0 0 25%;
  max-width: 25%;
}

.content-ai .col-4 {
  flex: 0 0 33.333333%;
  max-width: 33.333333%;
}

.content-ai .col-md-4 {
  flex: 0 0 33.333333%;
  max-width: 33.333333%;
}

.content-ai .col-5 {
  -webkit-box-flex: 0;
  flex: 0 0 41.6667%;
  max-width: 41.6667%;
}

.content-ai .col-6 {
  flex: 0 0 50%;
  max-width: 50%;
}

.content-ai .col-7 {
  -webkit-box-flex: 0;
  flex: 0 0 58.3333%;
  max-width: 58.3333%;
}

.content-ai .col-8 {
  flex: 0 0 66.666667%;
  max-width: 66.666667%;
}

.content-ai .col-md-8 {
  flex: 0 0 66.666667%;
  max-width: 66.666667%;
}

.content-ai .col-9 {
  flex: 0 0 75%;
  max-width: 75%;
}

.content-ai .col-10 {
  flex: 0 0 83.333333%;
  max-width: 83.333333%;
}

.content-ai .col-12 {
  flex: 0 0 100%;
  max-width: 100%;
}

.content-ai .text-right {
  text-align: right !important;
}

.content-ai .mr-2 {
  margin-right: 0.5rem !important;
}

.content-ai img {
  vertical-align: middle;
  border-style: none;
}

.content-ai button,
.content-ai input {
  overflow: visible;
}

.content-ai button,
.content-ai input {
  margin: 0;
  font-family: inherit;
  font-size: inherit;
  line-height: inherit;
}

.content-ai button {
  text-transform: none;
}

.content-ai h2 {
  margin-bottom: 0.5rem;
  line-height: 1.2;
  margin-top: 0;
}

.content-ai input[type="checkbox"] {
  box-sizing: border-box;
  padding: 0;
}

.content-ai hr {
  margin-top: 1rem;
  margin-bottom: 1rem;
  border: 0;
  height: 0;
  overflow: visible;
}

.content-ai b,
.content-ai strong {
  font-weight: bolder;
}

.content-ai p {
  margin-top: 0;
  margin-bottom: 1rem;
}

.content-ai .collapse.show {
  display: block;
}

.content-ai a {
  color: #007bff;
  text-decoration: none;
  background-color: transparent;
}

.content-ai ul {
  margin-bottom: 1rem;
}

.content-ai ol {
  margin-top: 0;
  margin-bottom: 1rem;
}

.content-ai .d-flex {
  display: flex !important;
}

.content-ai .mr-3 {
  margin-right: 1rem !important;
}

.content-ai .mb-3 {
  margin-bottom: 1rem !important;
}

.content-ai .nav {
  display: flex;
  flex-wrap: wrap;
  list-style: none;
}

.content-ai .nav-pills .nav-link {
  border-radius: 0.25rem;
}

.content-ai .nav-link {
  display: block;
  padding: 0.5rem 1rem;
}

.content-ai .nav-pills .nav-link.active,
.content-ai .nav-pills .show > .nav-link {
  color: #fff;
  background-color: #007bff;
}

/*------------------------------*/
/*__Bootstrap classes complete__*/
/*------------------------------*/

.content-ai * {
  font-family: "Nunito Sans", sans-serif !important;
  padding: revert; /*To revert padding:0 set in login.css. Effects list padding*/
}
.content-ai body {
  margin: 0;
  padding: 0;
  background-color: #f3f6fa;
}
.content-ai .container {
  max-width: 1366px;
}
.content-ai header {
  background-color: transparent;
  padding: 20px 0 10px;
}

.content-ai .custom-row {
  display: flex;
  flex-wrap: wrap;
}

.content-ai input[type="checkbox"] {
  accent-color: #034ea2;
}

.content-ai .custom-checkbox {
  -moz-appearance: none;
  -webkit-appearance: none;
  -o-appearance: none;
  outline: none;
  content: none;
}

.content-ai .right-container {
  background-color: #fff;
  padding: 20px;
  border-radius: 10px;
  overflow-y: scroll;
  height: calc(100vh - 62px - 3rem); /*Header rendered height: 62px, py-4: 1.5rem + 1.5rem*/
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
}
.content-ai .right-container .title {
  font-size: 16px;
  color: #231f20;
  font-weight: 600;
}

.content-ai .right-controls {
  display: flex;
  justify-content: end;
  align-items: center;
}

.content-ai .menu-arrow {
  position: fixed;
  top: 413px;
  z-index: 100;
  width: 16px;
  padding-left: 7px;
  cursor: pointer;
  margin-left: -10px;
  left: 10px;
}
.content-ai .menu-arrow-close {
  left: 10px;
  background-color: #034ea2;
  position: fixed;
  top: 290px;
  z-index: 100;
  width: 16px;
  padding-left: 4px;
  padding-right: 3px;
  margin-left: -10px;
  cursor: pointer;
  border-radius: 0px 10px 10px 0;
  height: 260px;
}

.content-ai .modules-list {
  display: flex;
  align-items: center;
  gap: 5px;
  font-size: 11px;
  font-weight: 600;
}
.content-ai .modules-list a.active {
  background-color: #034ea2;
  color: #fff;
}
.content-ai .modules-list a {
  color: #034ea2;
  padding: 5px 8px;
  text-transform: uppercase;
  border-radius: 3px;
  letter-spacing: 0.16px;
}

.content-ai .fixed-menu {
  left: 10px;
  background-color: #034ea2;
  position: fixed;
  top: 290px;
  z-index: 99;
  color: #fff;
  padding: 20px;
  list-style-type: none;
  border-radius: 0px 10px 10px 0;
  margin-left: -10px;
}
.content-ai .fixed-menu li {
  padding: 6px 0 6px 12px;
  font-weight: 500;
  margin-bottom: 8px;
}
.content-ai .fixed-menu li a {
  color: #fff;
  text-decoration: none;
  padding: 6px 0 6px 12px;
  font-weight: 500;
  margin-bottom: -12px;
  display: block;
}
.content-ai .fixed-menu li a.active,
.content-ai .fixed-menu li a:hover {
  background-color: #fff;
  color: #034ea2 !important; /*To prevent override from bootstrap.css a:hover. Would be removed after completely localizing the bootstrap.css*/
  border-radius: 4px;
}

.content-ai .tagline {
  font-size: 20px;
  margin-top: 10px;
  margin-left: 10px;
  margin-bottom: 0px;
  font-weight: 600;
}
.content-ai .banner-card {
  background-image: url("https://devkraftgenai.s3.ap-south-1.amazonaws.com/icons/banner.png");
  min-height: 531px;
  background-size: cover;
  text-align: center;
  padding: 120px 80px;
  height: calc(100vh - 235px);
  border-radius: 30px;
}
.content-ai .banner-card span.text {
  color: #fff;
  border: 3px solid #fff;
  padding: 4px 20px;
  font-size: 72px;
  border-radius: 10px;
  margin-bottom: 30px;
  margin-top: 20px;
}
.content-ai .banner-card p {
  color: #fff;
  font-size: 32px;
  margin-top: 30px;
  margin-bottom: 60px;
}
.content-ai .banner-card .textInput {
  margin: 0 auto;
  /* width: 90%; */
  width: 680px;
  background: transparent linear-gradient(261deg, #375bf4 0%, #1fcbff 49%, #1f9ee5 100%) 0% 0% no-repeat padding-box;
  height: 77px;
  box-shadow: 0px 3px 6px #4ee5f8a5;
  border-radius: 14px;
  padding: 8px 0;
}
.content-ai .banner-card .textInput .row {
  text-align: left;
  background: #ffffff 0% 0% no-repeat padding-box;
  border-radius: 10px;
  height: 61px;
  margin: 0 7px;
  padding: 13px 0;
}
.content-ai .banner-card .textInput .row input {
  width: calc(100% - 45px);
  border: 1px solid #fff;
  font-size: 18px;
  position: relative;
  top: 4px;
}
.content-ai .banner-card .textInput .row input:hover,
.content-ai .banner-card .textInput .row input:focus {
  outline: none;
}
.content-ai .banner-card .textInput .row button {
  color: #fff;
  font-size: 12px;
  background-color: #034ea2;
  border: 0;
  padding: 8px 18px;
  /*  width: 100%; */
  position: relative;
  /*  top: 4px; */
  border-radius: 3px;
  cursor: pointer;
}
.content-ai .banner-card .textInput .row button:hover {
  text-decoration: none;
  background-color: #034ea2;
  color: #fff;
}
.content-ai .banner-card .textInput .row button:disabled {
  cursor: not-allowed;
  background-color: #cccccc;
  color: #666666;
}

/* .content-ai h2 {
  font-size: 19px !important;
  color: #231f20 !important;
  letter-spacing: 0px !important;
  font-weight: 800 !important;
} */

.content-ai .generate-response-screen {
  display: flex;
  flex-direction: column;
  gap: 2rem;
}
.content-ai .module-visual-block {
  display: grid;
  width: 85%;
  margin: auto;
}
@media screen and (max-width: 1080px) {
  .content-ai .grid-css {
    grid-template-columns: 1fr 1fr;
  }
}
@media screen and (min-width: 1080px) {
  .content-ai .grid-css {
    /* grid-template-columns: 2fr 3fr; */
    grid-template-columns: 3fr 3.5fr;
  }
}
.content-ai .chat-bot-box {
  /* margin-left: 42%; */
  text-align: right;
  position: relative;
}
.content-ai .chat-bot-input {
  position: absolute;
  bottom: 0;
  /* left: -21%; */
  right: 190px;
  user-select: none;
  display: flex;
  align-items: center;
  height: 2.5rem;
  width: 12rem;
  border: none;
  background: #fff;
  border-radius: 8px;
  color: #034ea2;
  font-size: 15px;
  padding: 0 24px;
  font-weight: 600;
}

.content-ai .chat-bot-input-cursor {
  animation: chat-bot-input-cursor-blink 0.8s linear infinite;
}
@keyframes chat-bot-input-cursor-blink {
  0% {
    height: 24px;
  }
  50% {
    height: 16px;
  }
  100% {
    height: 24px;
  }
}

.content-ai .chat-bot-image {
  height: 200px;
  /*   width: 444px;
  height: 369px; */
  flex-shrink: 0;
  animation: chat-bot-translate 4s linear infinite;
}
@keyframes chat-bot-translate {
  10% {
    transform: translate(0px, 0px);
  }
  40% {
    transform: translate(-20px, -20px);
  }
  60% {
    transform: translate(-20px, -20px);
  }
  90% {
    transform: translate(0px, 0px);
  }
}

.content-ai .module-title {
  /*  font-size: 62px; */
  font-size: 30px;
  font-weight: 900;
  /* line-height: 85px; */
  letter-spacing: 0em;
  text-align: center;
  margin-bottom: 0;
}
.content-ai .module-description {
  /*  font-size: 32px; */
  font-size: 18px;
  font-weight: 400;
  /* line-height: 44px; */
  letter-spacing: 0em;
  text-align: center;
  color: #66676b;
}

.content-ai .question-block {
  border-radius: 20px;
  background-color: #fff;
  width: 85%;
  margin: auto;
  /*   margin-top: 1rem; */
}
.content-ai .question-block-title {
  border-radius: 20px 20px 0 0;
  background: #6182bd;
  padding: 15px 20px;

  color: #fff;
  /*  font-size: 26px; */
  font-size: 18px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
}
.content-ai .question-outer-block {
  padding: 24px;
}
.content-ai .question-input-block {
  display: flex;
  padding: 15px;
  justify-content: space-between;
  align-items: center;
  border-radius: 10px;
  border: none;
  background: #eef2ee;
  gap: 20px;
}
.content-ai .question-input-block input {
  width: 100%;
  font-size: 18px;
  outline: none;
  background: inherit;
  border: inherit;
}
.content-ai .question-input-block button {
  border-radius: 6px;
  font-size: 14px;
  background-color: #034ea2;
  line-height: 22px;
  letter-spacing: 0.6px;

  /* width: 124px; */
  /*   height: 52px; */
  height: 46px;
  padding: 4px 10px;
}
.content-ai .question-input-block button:hover {
  text-decoration: none;
  background-color: #6182bd !important; /*To override antd default hover color*/
  color: #fff;
}
.content-ai .question-input-block button:disabled {
  cursor: not-allowed;
  background-color: #cccccc;
  color: #666666;
}

.content-ai .prompt-title-block {
  font-size: 19px;
  color: #231f20;
  letter-spacing: 0px;
  font-weight: 800;

  display: flex;
  align-items: center;

  /* font-size: 24px; */
  font-size: 20px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}
.content-ai .prompt-title-block a,
.content-ai .prompt-title-block a:hover {
  color: #231f20 !important;
  text-decoration: none !important;
}

.content-ai .prompt-title-block .button-group {
  display: flex;
  align-items: center;
  gap: 1rem;
}
.content-ai .prompt-title-block .edit {
  font-size: 12px !important;
  color: #004ea2 !important;
  padding: 8px 10px !important;
}
.content-ai .prompt-title-block .button {
  font-size: 12px !important;
  background-color: #004ea2 !important;
  color: #fff !important;
  padding: 8px 10px !important;
  border-radius: 3px;
}

.content-ai .leftPaneBlock {
  background-color: #f6f7f7;
  margin: 20px;
  padding: 20px;
  border-radius: 10px;
  height: calc(100vh - 62px - 3rem - 40px); /*Header rendered height: 62px, py-4: 1.5rem + 1.5rem, margin: 2x20px*/
}
.content-ai .content-universe .leftPaneBlock {
  height: calc(100vh - 62px - 3rem - 40px - 35px); /*Header rendered height: 62px, py-4: 1.5rem + 1.5rem, margin: 2x20px, 35px Tags list height*/
}
.content-ai .leftPaneBlock .title {
  font-size: 18px;
  color: #231f20;
  font-weight: 700;
}

.content-ai .initial-response {
  overflow-y: scroll;
  /*  height: calc(100vh - 235px); */
  height: calc(100vh - 62px - 3rem); /*Header rendered height: 62px, py-4: 1.5rem + 1.5rem*/
}

.content-ai .textBlock {
  background-color: #fff;
  /* margin: 20px 15px; */
  padding: 20px;
  border-radius: 10px;
}
.content-ai .textBlock .title {
  font-size: 18px;
  color: #231f20;
  font-weight: 700;
}
.content-ai .textBlock .title span {
  color: #004ea2;
}
.content-ai .socialLinks {
  text-align: right;
}
.content-ai .socialLinks span {
  margin-left: 15px;
  color: #004ea2;
  font-weight: 600;
  cursor: pointer;
}
.content-ai .socialLinks span img {
  position: relative;
  top: -2px;
}
.content-ai .textBlock p {
  font-size: 16px;
  color: #231f20;
  margin-top: 10px;
}
.content-ai hr {
  /*  border-top: 2px dashed #ddd; */
  border-top: 1px dashed #bababa;
}
.content-ai .hr-solid-line {
  border-top: 1px solid #bababa;
}

.content-ai .maximise,
.content-ai .minimise {
  cursor: pointer;
}
.content-ai ul {
  padding-left: 16px;
  margin-top: 10px;
  font-size: 15px;
}

.content-ai .filters a {
  color: #666666;
  font-size: 14px;
}

.content-ai .brandList {
  padding-left: 35px;
  margin-top: 8px;
  font-size: 14px;
}
.content-ai .brandList label {
  display: block;
  margin: 0 0 2px 0;
}
.content-ai a.list {
  text-decoration: none;
}
.content-ai .list span {
  display: hide;
}
.content-ai .list span.show {
  display: inline-block;
}

.content-ai .hide {
  display: none;
}

.content-ai #tags {
  margin-top: 10px;
}
.content-ai #tags span {
  color: #004ea2;
  border: 1px solid #004ea2;
  border-radius: 14px;
  font-size: 12px;
  padding: 3px 8px;
  margin-right: 8px;
  margin-bottom: 10px;
  display: inline-block;
}
.content-ai #tags span i {
  font-style: inherit;
  cursor: pointer;
  font-size: 14px;
  margin-left: 8px;
}

.content-ai .rules {
  border-radius: 6px;
  background-color: #fff;
}
.content-ai .rules:nth-child(even) {
  background-color: #f4f6f9;
}
.content-ai .rules .heading {
  color: #231f20;
  padding: 15px;
}
.content-ai .rules .applicability {
  color: #034ea2;
  padding: 15px 0;
}

.content-ai .checkboxList {
  padding-left: 0px;
  margin-top: 8px;
  font-size: 14px;
}
.content-ai .checkboxList label {
  display: block;
  margin: 0 0 2px 0;
  cursor: pointer;
}
.content-ai .px-35 {
  padding: 0 35px;
  margin-top: 20px;
}
.content-ai .listTitle {
  /* color: #666666; */
  font-weight: 600;
  font-size: 14px;
}
.content-ai .closeBtn {
  color: #004ea2;
  font-size: 14px;
  cursor: pointer;
  padding: 4px 10px;
}
.content-ai .updateBtn {
  border-radius: 3px;
  background-color: #004ea2;
  color: #fff;
  font-size: 14px;
  padding: 4px 10px;
  margin: 0 10px 20px;
  cursor: pointer;
}

.content-ai .brandCard,
.brandCard:hover {
  background-color: #034ea2;
  height: 140px;
  color: #fff;
  text-align: center;
  font-size: 22px;
  padding-top: 55px;
  cursor: pointer;
  display: block;
  text-decoration: none;
}
.content-ai .nav-link,
.nav-link:focus {
  border: 1px solid #ddd;
  margin-right: 10px;
  outline: 0;
  cursor: pointer;
}
.nav-link.active {
  background-color: #034ea2;
}
.content-ai .contentBlock {
  background-color: #fff;
  margin: 20px 15px 0;
  padding: 20px;
  border-radius: 10px;
  overflow-y: scroll;
  height: calc(100vh - 365px);
}
.content-ai .tab-pane {
  animation-duration: 0.5s;
  animation-name: example;
}
@keyframes example {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

.content-ai .loading-dots:after {
  animation: dots 3s linear infinite;
  content: "";
  white-space: pre;
}

@keyframes dots {
  0%,
  20% {
    content: ".  ";
  }
  40% {
    content: ".. ";
  }
  60% {
    content: "...";
  }
  90%,
  100% {
    content: "   ";
  }
}

.content-ai .refined-response {
  /*  overflow-y: scroll; */
  /*  height: calc(100vh - 235px); */
  height: calc(100vh - 62px - 3rem); /*Header rendered height: 62px, py-4: 1.5rem + 1.5rem*/
}

.content-ai .refined-response-left-block {
  margin-top: 20px;
  padding: 20px;
  border-radius: 18px;
  background: #e9f8ff;
  box-shadow: 0px 0px 10px 10px rgba(0, 0, 0, 0.02);
  height: calc(
    100vh - 62px - 3rem - 40px - 27px - 20px
  ); /*Header rendered height: 62px, py-4: 1.5rem + 1.5rem,  padding: 2x20px, title height: 27px, margin-top 20px*/

  display: flex;
  flex-direction: column;
}
.content-ai .refined-response-left-block .title {
  font-size: 16px;
}

.content-ai .refined-response-left-block .button-group {
  display: flex;
  align-items: center;
}
.content-ai .refined-response-left-block .edit {
  font-size: 12px !important;
  color: #004ea2 !important;
  padding: 0 10px !important;
  margin-right: 1rem;
}
.content-ai .refined-response-left-block .button {
  font-size: 12px !important;
  background-color: #004ea2 !important;
  color: #fff !important;
  padding: 8px 10px !important;
  border-radius: 3px;
}

.content-ai .refined-response-left-block .user-prompt {
  flex: 0 0 auto;
  margin-bottom: 0;
  font-size: 16px;
}
.content-ai .applied-rules-title {
  font-size: 16px;
  color: #231f20;
  font-weight: 700;
  flex: 0 0 auto;
}
.content-ai .refined-response-rules {
  flex: 1 1 auto;
  overflow-y: auto;
}
.content-ai .refined-response-rules ol {
  padding-left: 16px;
  margin-top: 10px;
  font-size: 15px;
  margin-bottom: 1rem;
  line-height: 1.5rem;
}
.content-ai .refined-response-right-block {
  padding: 20px 8px;
  border-radius: 18px;
  height: calc(100vh - 62px - 3rem - 40px - 27px); /*Header rendered height: 62px, py-4: 1.5rem + 1.5rem, padding: 2x20px, title height: 27px*/

  display: flex;
  flex-direction: column;
}
.content-ai .refined-response-right-block > {
  flex: "0 0 auto";
}
.content-ai .refined-response-right-block .refined-response-html {
  text-align: left;
  flex: 1 1 auto;
  overflow-y: auto;
  font-size: 16px;
}

.content-ai .cu-right-container {
  background-color: #fff;
  padding: 20px;
  border-radius: 10px;
  /* overflow-y: scroll; */
  height: calc(100vh - 62px - 3rem - 35px); /*Header rendered height: 62px, py-4: 1.5rem + 1.5rem, 35px Tags list height*/
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
}
.content-ai .cu-content-container {
  display: flex;
  flex: 1 1 auto;
  overflow-y: auto;
}
.content-ai .cu-content-container > div {
  overflow-y: scroll;
}

.content-ai .cu-button-group {
  flex: "0 0 auto";
}

.content-ai .cu-tags {
  margin-top: 10px;
}
.content-ai .cu-tags span {
  color: #004ea2;
  /*  border: 1px solid #004ea2; */
  border-radius: 14px;
  font-size: 15px;
  font-weight: 600;
  padding: 3px 8px;
  margin-right: 8px;
  margin-bottom: 10px;
  display: inline-block;
}
.content-ai .cu-tags span.selected {
  background-color: #004ea2;
  color: #fff;
}

.content-ai .cu-content-footer {
  display: flex;
  justify-content: space-between;
  color: #66676b;
}

.content-ai .cu-filtered-tags {
  border-radius: 10px;
  background: #e9f8ff;
}

.content-ai .updateBtn.disabled {
  pointer-events: none;
  background: #e6e7e8;
  color: #66676b;
}

.content-ai .edit-rule-input {
  height: 70%;
  outline: none;
  border: none;
  width: 100%;
  background: transparent;
  padding: 0 15px;
}

.content-ai .disable-interaction * {
  pointer-events: none;
  color: #66676b;
}

.content-ai .cu-tabs .ant-tabs-tab {
  background-color: #fff;
  color: #034ea2;
  padding: 5px 16px;
}
.content-ai .cu-tabs .ant-tabs-tab.ant-tabs-tab-active {
  background-color: #034ea2;
}
.content-ai .cu-tabs .ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn {
  color: #fff;
}

.content-ai .cu-upload-screen {
  height: calc(100vh - 62px - 3rem - 35px); /*Header rendered height: 62px, py-4: 1.5rem + 1.5rem, 35px Tabs List height*/
}

.content-ai .cu-upload-left-pane {
  margin: 20px;
}
.content-ai .cu-upload-left-pane .step {
  color: #66676b;
  margin: 0 5px;
  font-weight: 600;
  font-size: 12px;
}
.content-ai .cu-upload-left-pane .title {
  font-size: 16px;
  color: #231f20;
  font-weight: 700;
  margin: 0 5px 15px;
}
.content-ai .cu-upload-left-pane .tags-block {
  /*Header rendered height: 62px, py-4: 1.5rem + 1.5rem, 35px Tabs List height, 2x20px margin, ~60px "Step" and "Choose Details"*/
  height: calc(100vh - 62px - 3rem - 35px - 40px - 60px);
  padding: 20px;
  background-color: #f6f7f7;
  border-radius: 10px;
  display: flex;
  flex-direction: column;
  gap: 10px;
}
.content-ai .cu-upload-left-pane .tags-block .tags {
  flex: 1 1 auto;
  display: flex;
  flex-direction: column;
  overflow: auto;
}
.content-ai .cu-upload-left-pane .brand-heading {
  color: #034ea2;
  font-size: 14px;
  font-weight: 600;
  cursor: pointer;
  display: flex;
  align-items: center;
  gap: 5px;
}

.content-ai .cu-upload-right-pane {
  margin: 20px;
  margin-left: 60px;
}
.content-ai .cu-upload-right-pane .step {
  color: #66676b;
  margin: 0 5px;
  font-weight: 600;
  font-size: 12px;
}
.content-ai .cu-upload-right-pane .title {
  font-size: 16px;
  color: #231f20;
  font-weight: 700;
  margin: 0 5px 15px;
}

.content-ai .cu-file-upload {
  background-color: #effaff;
  font-size: 18px;
  border-radius: 8px;
  height: 85%;
}
.content-ai .cu-upload-hint {
  color: #66676b;
  font-size: 18px;
  text-transform: capitalize;
}

.content-ai .cu-files-list-area {
  height: calc(100vh - 62px - 3rem - 35px - 40px - 60px);
  display: flex;
  flex-direction: column;
}

.content-ai .cu-files-list-area .title {
  font-size: 14px;
  color: #231f20;
  font-weight: 700;
  margin: 0 0 15px 0;
}

.content-ai .cu-upload-footer {
  color: #66676b;
  font-size: 12px;
  display: flex;
  justify-content: space-between;
  margin-top: 10px;
}

.content-ai .cu-files-list {
  display: flex;
  flex-direction: column;
  gap: 10px;
  flex: 1 1 auto;
  overflow: auto;

  padding-right: 10px;
}

.content-ai .file-icon {
  padding: 3px 4px 5px 4px;
  margin: 0px 5px 20px 0px;
  border: 0.3px solid #d2d3d3;
  border-radius: 3px;
}

.content-ai .fileview {
  display: flex;
  border: 0.5px solid #d2d3d3;
  border-radius: 5px;
  padding: 5px;
}

.content-ai .fileview.error {
  background: #f6e2e2;
}
.content-ai .cu-error-message {
  font-size: 10px;
  color: red;
}

.content-ai .file-description {
  display: flex;
  justify-content: space-between;
}
.content-ai .file-size {
  font-size: 10px;
  color: #797a7b;
}

.content-ai .upload-progress {
  display: flex;
  align-items: center;
  gap: 5px;
}
.content-ai .upload-progress-bar-area {
  border-radius: 10px;
  /*  background: #368a04; */
  height: 8px;
  width: 100%;
}
.content-ai .upload-progress-bar {
  border-radius: 10px;
  background: #368a04;
  height: 8px;
}
.content-ai .upload-progress-bar.shimmer {
  -webkit-mask: linear-gradient(-60deg, #000 30%, #0005, #000 70%) right/300% 100%;
  animation: shimmer 2.5s infinite;
}

@keyframes shimmer {
  100% {
    -webkit-mask-position: left;
  }
}

.content-ai .upload-percentage {
  color: #797a7b;
  font-size: 10px;
  width: 40px;
}

.content-ai .file-details {
  display: flex;
  flex-direction: column;
  width: 100%;
  gap: 5px;
}

.content-ai .no-files-found {
  text-align: center;
  padding: 5px;
  border: 0.5px solid #d2d3d3;
  border-radius: 5px;
}
