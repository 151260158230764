.format-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  backdrop-filter: blur(6px); /* Background blur effect */
  background: rgba(44, 78, 101, 0.5); /* semi-transparent background with the color #2C4E65 */
  display: flex;
  align-items: center;
  justify-content: center;
  transition: transform 1s ease-in-out; /* transition effect */
  z-index: 999999;
}

.format-close-button {
  position: fixed;
  z-index: 99999999999;
  top: 16px;
  font-weight: 600;
  font-size: 30px;
  right: 30px;
  cursor: pointer;
}

.format-content-container {
  padding-left: 10px;
  margin-top: 10px;
}

.format-padding-container {
  padding: 30px;
}

.format-title {
  font-weight: 700;
  font-size: 16px;
  color: #231F20;
}

.format-radio-group {
  margin-left: 5px;
}

.format-radioLabel {
  margin-left: 8px;
}

.format-selected {
  background-color: #e6f7ff;
}

.format-table-container {
  margin: 25px;
  border-radius: 10px;
}

.format-table-header {
  background: #ECF9FF;
  justify-content: center;
  border-radius: 10px;
  padding: 10px;
}

.format-table-header-col {
  display: flex;
  justify-content: center;
  align-items: center;
}

.format-table-header-content {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 10px;
  flex-direction: column;
}

.format-input-container {
  display: flex;
  margin-top: 1px;
  box-shadow: 0px 0px 6px 0px #00000014;
  border-radius: 10px;
}

.format-input {
  width: 140px;
  border: none;
}

.format-input-buttons {
  display: flex;
  gap: 5px;
  border-top-right-radius: 6px;
  border-bottom-right-radius: 6px;
  padding-right: 5px;
  background-color: white;
}

.format-button {
  background: none;
  border: none;
  font-size: 20px;
  color: #034EA2;
  cursor: pointer;
}

.format-separator {
  margin-top: 3px;
  color: #D4D4D4;
}

.format-generate-button-col {
  padding-left: 30px;
  border-left: 2px solid #BEEBFF;
}

.format-generate-button-col-container {
  margin-top: 20px;
  height: 30px;
  display: flex;
  padding-left: 30px;
  justify-content: left;
  align-content: center;
}
.format-generate-button-schema{

}

.format-notgenerated-content {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.format-notgenerated-icon {
  display: flex;
  justify-content: center;
}

.format-notgenerated-text {
  margin-top: 10px;
}


.format-generated-header {
  display: flex;
  gap: 10px;
  margin-left: 45px;
}

.format-generated-title {
  font-weight: 700;
  font-size: 16px;
}

.format-generated-separator {
  font-weight: 700;
  font-size: 16px;
}

.format-generated-subtitle {
  font-weight: 700;
  font-size: 16px;
  color: #66676B;
}

.format-nottableformat-content {
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 100px;
}

.format-nottableformat-icon {
  display: flex;
  justify-content: center;
}

.format-nottableformat-text {
  margin-top: 10px;
}


.format-generate-main-button {
  background: #034EA2;
  color: white;
  cursor: pointer;
}

.format-colStyle {
    height: 80vh;
    background: #ECF9FF;
    border-radius: 10px;
  }
  
  .format-colStyle2 {
    height: 80vh;
    border-radius: 10px;
    margin-left: 30px; 
    border: 1px solid #2C4E659E;
  } 
  .cardStyle {
    height: 88vh;
  }
  .format-radioContainer {
    border-radius: 6px;
    border: 2px solid #034EA2;
    padding: 7px;
    margin: 15px;
    display: flex;
    gap: 10px;
    cursor: pointer;
  }
  
  .format-radioContainer.selected {
    background: #034EA2;
    color: white;
  }
  .format-notgenerated-container {
    display: flex;
    justify-content: center;
    align-content: center;
    padding-top: 130px;
    height: 337px;

  }
  .format-generated-container {
    height: 337px;
    overflow-y: auto;
    overflow-x: hidden;
   
  }
  .format-nottableformat {
    display: flex;
    justify-content: center;
    align-content: center;
    margin-top: 260px;
    height: 220px;
  }
  .format-generate-button {
    margin-top: 3vh;
    display: flex;
    justify-content: flex-end;
    border-top: 2px dashed #C7C7C7;
    padding-top: 20px;
    margin-right: 20px;
    margin-left: 20px;
  }
  
  @media (max-height: 600px) {
    .format-notgenerated-container {
      height: 185px;
    }
    .format-generated-container {
      height: 185px;
    }
    .format-nottableformat {
      margin-top: 180px;
      height: 160px;
    }
    .format-generate-button {
      margin-top: 9vh;
    }
    .format-radioContainer {
        /* Adjust styles for screens with a height larger than 900px */
        margin: 8px;
        padding: 4px;
    }
  }
  
  /* For heights between 601px and 900px */
  @media (min-height: 601px) and (max-height: 700px) {
    .format-notgenerated-container {
      height: 215px;
    }
    .format-generated-container {
      height: 215px;

      
    }
    .format-nottableformat {
      margin-top: 190px;
      height: 175px;
    }
    .format-generate-button {
      margin-top: 60px;
    }
    .format-radioContainer {
        /* Adjust styles for screens with a height larger than 900px */
        margin: 10px;
        padding: 5px;
    
      }
    
  }
  
  @media (min-height: 800px) and (max-height: 900px) {
    .format-notgenerated-container {
      height: 300px;
    }
    .format-generated-container {
      height: 300px; 
    }
    .format-nottableformat {
      margin-top: 240px;
      height: 192px;
    }
    .format-generate-button {
      margin-top:15vh; 
    }
    .format-radioContainer {
        /* Adjust styles for screens with a height larger than 900px */
        margin: 15px;
        padding: 7px;
      
      }
    
  }
  /* For heights greater than 900px */
  @media (min-height: 901px) {
    .format-notgenerated-container {
      margin-top: 25vh;
    }
    .format-generated-container {
      height: 35vh;
    }
    .format-nottableformat {
      margin-top: 250px;
      height: 160px;
    }
    .format-generate-button {
      margin-top: 18vh;
    }
    .format-radioContainer {
        /* Adjust styles for screens with a height larger than 900px */
        margin: 17px;
        padding: 9px;
        font-size: 18px;
      }
    
  }
  