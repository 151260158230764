.pptEditDownload.layout {
    height: 100vh;
    background: linear-gradient(204.56deg, #efe2e6 -0.51%, #e9e7f5 32.16%, #f8e9e2 88.08%);
    border-radius: 30px;
    margin-top: 25px;
  }
  
  .pptEditDownload .layout-padding {
    padding: 30px;
  }
  
  .pptEditDownload .header {
    display: flex;
    justify-content: space-between;
  }
  
  .pptEditDownload .header-left {
    display: flex;
    gap: 10px;
  }
  
  .pptEditDownload .header-left span {
    font-size: 20px;
    font-weight: 400;
    color: #2C2C2C;
  }
  
  .pptEditDownload .header-left .change-text {
    font-size: 16px;
    font-weight: 700;
    color: #1E4DA1;
    margin-top: 4px;
    cursor: pointer;

  }
  
  .pptEditDownload .header-right {
    display: flex;
    gap: 20px;
  }
  
  .pptEditDownload .header-right img {
    height: 25px;
    padding-top: 7px;
  }
  
  .pptEditDownload .header-right .regenerate-text {
    font-size: 14px;
    font-weight: 600;
    color: #1E4DA1;
    padding-top: 7px;
    cursor: pointer;

  }
  
  .pptEditDownload .button {
    background: none;
    border: 1px solid #1E4DA1;
    color: #1E4DA1;
    font-size: 14px;
    font-weight: 600;
    border-radius: 4px;
    padding: 6px 14px;
    padding-bottom: 10px;
    cursor: pointer;

  }
  
  .pptEditDownload .button-primary {
    background: #1E4DA1;
    border: 1px solid #1E4DA1;
    color: white;
    font-size: 14px;
    font-weight: 600;
    border-radius: 4px;
    padding: 6px 12px;
    cursor: pointer;
   
  }
  .pptEditDownload .go-back{
    background: #1E4DA1;
    border: 1px solid #1E4DA1;
    color: white;
    font-size: 14px;
    font-weight: 600;
    border-radius: 4px;
    padding: 6px 12px;
    cursor: pointer;
    margin-bottom: 10px;


  }
  
  .pptEditDownload .main-content {
    margin-top: 25px;
    margin-left: 15px;
  }
  
  .pptEditDownload .row {
    gap: 200px;
  }
  
  .pptEditDownload .col {
    span: 4;
  }
  
  .pptEditDownload .outline-container,
  .pptEditDownload .page {
    background: white;
    padding: 10px;
    border-radius: 6px;
    color: black;
  }
  
  .pptEditDownload .outline-container {
    margin-top: 10px;
    font-size: 12px;
    padding: 14px;
    font-weight: 600;
    height: 270px;
    overflow-y: scroll;
  }
  
  .pptEditDownload .pages-container {
    margin-top: 25px;
    overflow-y: scroll;
    height: 310px;
  }
  
  .pptEditDownload .page {
    margin-top: 10px;
    height: 250px;
    overflow-y: hidden;
  }
  .pptEditDownload .pagetitle{
    font-size: 12px;
    font-weight: 600;
  }
  .pptEditDownload .pagecontent{
    margin-top :5px;
    font-size: 10px;
    font-weight: 500;
  }
  
  .pptEditDownload .edit-notice {
    font-size: 15px;
    color: #818181;
    font-weight: 600;
    margin-left: 10px;
  }
  
  .pptEditDownload .slide-container {
    margin-top: px;
    height: 500px;
    background: white;
    border-radius: 6px;
    padding: 10px; 
    width: 800px;
  }
  
  .pptEditDownload .title{
    background: white;
    padding: 10px;
    border-radius: 6px;
    padding-left:'30px';
    color: black;
    font-size: 32px;
    font-weight: 600;
    border: white;
    resize: none;
    padding-left: 20px;
    overflow: hidden;
  }
  .pptEditDownload .slide-content {
    background: white;
    padding: 10px;
    padding-left: 20px;
    border-radius: 6px;
    color: black;
    font-size: 18px;
    font-weight: 500;
    border: white;
    overflow: hidden;


  }
  .pptEditDownload .iframe-ppt-container{
    width: 800px; /* Set iframe width to 100% */
    height: 500px; /* Set iframe height */
    border-radius: 20px; /* Remove iframe border */

  }
  .pptEditDownload .button-primary-cancel {
    background: #1E4DA1;
    border: 1px solid #1E4DA1;
    color: white;
    font-size: 14px;
    font-weight: 600;
    border-radius: 4px;
    padding: 6px 12px;
    cursor: pointer;
  }
  



/* For screens between 768px and 1200px */
@media (min-width: 1300px) and (max-width: 1400px) {
  /* Add your styles here for medium-sized screens */
  .pptEditDownload .row {
    gap: 125px;
  }
}
@media (min-width: 1200px) and (max-width: 1300px) {
  /* Add your styles here for medium-sized screens */
  .pptEditDownload .row {
    gap: 80px;
  }
}
@media (min-height: 625px) and (max-height: 700px) {
  /* Add your styles here for medium-sized screens */
  .pptEditDownload .outline-container {
    margin-top: 10px;
    font-size: 12px;
    padding: 14px;
    font-weight: 600;
    height: 250px;
    overflow-y: scroll;
  }
  
  .pptEditDownload .pages-container {
    margin-top: 25px;
    overflow-y: scroll;
    height: 260px;
    margin-bottom:'10px';
  }
  .pptEditDownload .slide-container {
    margin-top: px;
    height: 450px;
    background: white;
    border-radius: 6px;
    padding: 10px; 
  }
  
  .pptEditDownload .title{
    background: white;
    padding: 10px;
    border-radius: 6px;
    padding-left:'30px';
    color: black;
    font-size: 28px;
    font-weight: 600;
    border: white;
    resize: none;
    padding-left: 20px;
    overflow: hidden;
  }
  .pptEditDownload .slide-content {
    background: white;
    padding: 10px;
    padding-left: 20px;
    border-radius: 6px;
    color: black;
    font-size: 14px;
    font-weight: 500;
    border: white;
    overflow: hidden;


  }
  .pptEditDownload .iframe-ppt-container{
    height: 450px; /* Set iframe height */
    border-radius: 20px; /* Remove iframe border */

  }
}
@media (min-height: 550px) and (max-height: 625px) {
  /* Add your styles here for medium-sized screens */
  .pptEditDownload .outline-container {
    margin-top: 10px;
    font-size: 12px;
    padding: 14px;
    font-weight: 600;
    height: 220px;
    overflow-y: scroll;
  }
  
  .pptEditDownload .pages-container {
    margin-top: 25px;
    overflow-y: scroll;
    height: 240px;
    margin-bottom:'10px';
  }
  .pptEditDownload .slide-container {
    margin-top: px;
    height: 420px;
    background: white;
    border-radius: 6px;
    padding: 10px; 
  }
  
  .pptEditDownload .title{
    background: white;
    padding: 10px;
    border-radius: 6px;
    padding-left:'30px';
    color: black;
    font-size: 26px;
    font-weight: 600;
    border: white;
    resize: none;
    padding-left: 20px;
    overflow: hidden;
  }
  .pptEditDownload .slide-content {
    background: white;
    padding: 10px;
    padding-left: 20px;
    border-radius: 6px;
    color: black;
    font-size: 12px;
    font-weight: 500;
    border: white;
    overflow: hidden;


  }
  .pptEditDownload .iframe-ppt-container{
    height: 400px; /* Set iframe height */
    border-radius: 20px; /* Remove iframe border */

  }
}



